import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import DefaultLayout from '@/layouts/default';
import SEO from '@/components/layout/seo';
import { useForm } from 'react-hook-form';
import { login } from '@/services/api';
import { Link, navigate } from 'gatsby';
import Masthead from '@/components/layout/masthead';
import LoginContent from '@/components/LoginContent';
import IdmMastheadContent from '@/components/IdmMastheadContent';

const Page = () => {
  // Login form
  const { register, getValues, setValue } = useForm();

  // State for form error and preventing double form submissions
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const formChanged = async (e) => {
    setError(false);
    e.preventDefault();
    setSubmitting(true);
    let loginData = getValues();
    let response = await login(loginData.email, loginData.password);
    setSubmitting(false);
    if (response.error) {
      setError(true);
      setValue('password', '');
    } else {
      navigate('/app/idm/');
    }
  };

  return (
    <DefaultLayout>
      <SEO title="Log in" keywords={[]} />
      <Masthead>
        <IdmMastheadContent />
      </Masthead>
      <Container className="my-0">
        <Row>
          <Col lg={6} className="mt-3 my-lg-5">
            <LoginContent />
          </Col>

          
          <Col lg={{ span: 4, offset: 1 }} className="mt-3 my-lg-5 p-5 shadow">

            {/* Quick change to disable future logins and creation of new accounts to the RTI website below */}

            {/* <p>The Partnership for Pre-K Improvement Tool has moved here {'>>> '} <a href="">wa-coachingcompanion.org</a></p>

            <p>If you have an existing account, please visit the new site above to continue your IDM assessments</p>

            <p>New and existing accounts will be able to continue the assessment via the link above.</p> */}

            {/*  */}
            {/* Disabling the below will disable users from logging in */}

            <h3>Log In</h3>
            {error && (
              <div className="alert alert-warning">
                Invalid email or password. Please try it again.
              </div>
            )}
            <Form onSubmit={(e) => formChanged(e)}>
              <Form.Group controlId="emailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  ref={register}
                  name="email"
                />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  ref={register}
                  name="password"
                />
              </Form.Group>

              <Button
                variant="primary"
                className="cute-button"
                type="submit"
                disabled={submitting}
              >
                {submitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Log in</span>
                )}
              </Button>
            </Form>
            <p className="mt-4">
              <Link to="/forgot/">Forgot your password?</Link>
              <br />
              <Link to="/register/">Register an Account</Link>
            </p>

            {/*  */}

          </Col>
          
          

        </Row>
      </Container>
    </DefaultLayout>
  );
};

export default Page;
